import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Table,
  Tabs,
  Image,
  Input,
  Avatar,
  notification,
  Tooltip,
  Select,
  Popconfirm
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import moment from 'moment';
import {
  businessAtom,
  showCreateSharedStoreAtom,
  reloadSharedStoresAtom,
  selectedInventoryItemAtom
} from '../../../atoms/Atoms';
import api from '../../../api/api';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';
import AssignSharedStoreModal from './shared-components/AssignSharedStoreModal';
import ItemHistoryModal from './shared-components/ItemHistoryModal';
import { thousand } from '../../utils/functions';

function Inventory() {
  const { Option } = Select;
  const { t } = useTranslation();
  const history = useHistory();
  const setShowCreateSharedStore = useSetRecoilState(showCreateSharedStoreAtom);
  const [reloadSharedStores, setReloadSharedStores] = useRecoilState(reloadSharedStoresAtom);
  const [selectedInventoryItem, setSelectedInventoryItem]
    = useRecoilState(selectedInventoryItemAtom);
  const business = useRecoilValue(businessAtom);
  const [isSearching, setIsSearching] = useState(false);
  const [isLoadingStores, setIsLoadingStores] = useState(false);
  const [lastSearch, setLastSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [size, setSize] = useState(20);
  const [sorting, setSorting] = useState(0);
  const [products, setProducts] = useState([]);
  const [totalItemsInSearch, setTotalItemsInSearch] = useState(0);
  const [sharedStores, setSharedStores] = useState([]);
  const [loading, setLoading] = useState(false);

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function searchProducts(query, page) {
    setIsSearching(true);
    const payload = {
      Query: query,
      Page: page,
      Size: size,
      Sorting: sorting,
      BusinessId: business.id
    };
    api
      .post(
        'inventory/search',
        payload
      )
      .then((response) => {
        if (response.data.success) {
          setIsSearching(false);
          setTotalItemsInSearch(response.data.data.total);
          setProducts(response.data.data.result);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setIsSearching(false);
        showMessage(error);
      });
  }

  function onSearch(value) {
    setLastSearch(value);
    setCurrentPage(0);
    searchProducts(value, 0);
  }

  function onTableChange(page, pageSize) {
    if (page - 1 !== currentPage) {
      setCurrentPage(page - 1);
      searchProducts(lastSearch, page - 1);
    } else {
      setSize(pageSize);
    }
  }

  function getSharedStores() {
    setIsLoadingStores(true);
    if (reloadSharedStores) {
      setReloadSharedStores(false);
    }
    api
      .get(`inventory/shared/${business?.id}`)
      .then((response) => {
        setIsLoadingStores(false);
        if (response.data.success) {
          setSharedStores(response.data.data.results);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setIsLoadingStores(false);
        showMessage(error);
      });
  }

  function copyInventory(fromStoreId) {
    setLoading(true);
    api
      .post(`inventory/shared/copy/${fromStoreId}/${business?.id}`, null)
      .then((response) => {
        setLoading(false);
        if (!response.data.success) {
          showMessage(response.data.error);
        } else {
          showMessage(t('copy_inventory_successful'));
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  }

  function removeSharedStore(storeId) {
    setLoading(true);
    api
      .post('inventory/shared/remove', {
        BusinessId: business?.id,
        SharedBusinessId: storeId
      })
      .then((response) => {
        setLoading(false);
        if (!response.data.success) {
          showMessage(response.data.error);
        } else {
          showMessage(t('store_removed_successfully'));
          setSharedStores(response.data.data.results);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  }

  if (!business) {
    history.push('/');
    return null;
  }

  useEffect(() => {
    onSearch('');
    getSharedStores();
  }, []);

  useEffect(() => {
    onSearch('');
    getSharedStores();
  }, [business]);

  useEffect(() => {
    if (reloadSharedStores) {
      getSharedStores();
    }
  }, [reloadSharedStores]);

  useEffect(() => {
    onSearch('');
  }, [sorting]);

  useEffect(() => {
    if (!selectedInventoryItem) {
      searchProducts(lastSearch, currentPage);
    }
  }, [selectedInventoryItem]);

  return (
    <div>
      <PageHeader
        className="px-0"
        title={t('inventory')}
      />
      <Tabs defaultActiveKey="0">
        <Tabs.TabPane tab={t('items')} key="0">
          <div>
            <div className="flex">
              <Input.Search
                key="itemSearch"
                placeholder={t('item_search_placeholder')}
                allowClear
                enterButton={t('search')}
                size="large"
                onSearch={onSearch}
              />
              <Select
                className="mt-1 ml-2"
                style={{ width: 150 }}
                onChange={(value) => setSorting(value)}
                value={sorting}
              >
                <Option value={0}>{t('default_sort')}</Option>
                <Option value={1}>ASC</Option>
                <Option value={2}>DESC</Option>
              </Select>
            </div>
            <Table
              size="small"
              className="mt-2"
              loading={isSearching}
              bordered
              pagination={{
                pageSize: size,
                showSizeChanger: true,
                defaultCurrent: 0,
                current: currentPage + 1,
                total: totalItemsInSearch,
                onChange: onTableChange
              }}
              columns={[
                {
                  title: t('image'),
                  key: 'image',
                  align: 'center',
                  width: 80,
                  className: 'text-xs',
                  render: (text) =>
                    <Image
                      width={50}
                      src={text.itemImage}
                      alt={`${text.brand} ${text.name} ${text.description}`}
                    />,
                },
                {
                  title: t('brand'),
                  key: 'brand',
                  align: 'left',
                  className: 'text-xs',
                  render: (text) =>
                    <span>{text.brand}</span>,
                },
                {
                  title: t('name'),
                  key: 'name',
                  align: 'left',
                  className: 'text-xs',
                  render: (text) =>
                    <span>{text.name}</span>,
                },
                {
                  title: t('description'),
                  key: 'description',
                  align: 'left',
                  className: 'text-xs',
                  render: (text) =>
                    <span>{text.description}</span>,
                },
                {
                  title: t('quantity_on_hand'),
                  key: 'quantityOnHand',
                  align: 'left',
                  className: 'text-xs',
                  render: (text) =>
                    <span>{thousand(text.quantityOnHand)}</span>,
                },
                {
                  title: t('pack'),
                  key: 'pack',
                  align: 'left',
                  className: 'text-xs',
                  render: (text) =>
                    <span>{text.pack}</span>,
                },
                {
                  title: t('actions'),
                  key: 'manage',
                  align: 'center',
                  width: 30,
                  className: 'text-xs',
                  render: (text, item) => (
                    <Button
                      size="small"
                      type="primary"
                      onClick={() => {
                        setSelectedInventoryItem(item);
                      }}
                    >
                      {t('manage')}
                    </Button>
                  ),
                },
              ]}
              dataSource={products}
            />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('shared_inventory')} key="1">
          <div>
            <Button
              className="float-right mb-2"
              size="small"
              type="primary"
              onClick={() => {
                setShowCreateSharedStore(true);
              }}
            >
              {t('add')}
            </Button>
            <Table
              size="small"
              className="mt-2"
              loading={isLoadingStores}
              bordered
              pagination="none"
              columns={[
                {
                  title: t('name'),
                  key: 'name',
                  align: 'left',
                  className: 'text-xs',
                  render: (text) =>
                    <span>{text.name}</span>,
                },
                {
                  title: t('actions'),
                  key: 'manage',
                  align: 'center',
                  width: 30,
                  className: 'text-xs',
                  render: (text, item) => (
                    <div className="flex">
                      <Popconfirm
                        key="copy_inv"
                        title={t('copy_inventory_prompt')}
                        okText={t('yes')}
                        onConfirm={() => {
                          copyInventory(item.id);
                        }}
                      >
                        <Button
                          className="mr-2"
                          loading={loading}
                          size="small"
                          type="primary"
                        >
                          {t('copy_inventory')}
                        </Button>
                      </Popconfirm>
                      <Popconfirm
                        key="remove"
                        title={t('remove_business')}
                        okText={t('yes')}
                        onConfirm={() => {
                          removeSharedStore(item.id);
                        }}
                      >
                        <Button
                          loading={loading}
                          size="small"
                          type="primary"
                          danger
                        >
                          {t('remove')}
                        </Button>
                      </Popconfirm>
                    </div>
                  ),
                },
              ]}
              dataSource={sharedStores}
            />
          </div>
        </Tabs.TabPane>
      </Tabs>
      <AssignSharedStoreModal />
      <ItemHistoryModal />
    </div>
  );
}

export default Inventory;
