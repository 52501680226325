import React, { useEffect, useState } from 'react';
import {
  Button,
  Table,
  Switch,
  Card,
  Select,
  DatePicker,
  Modal,
  Spin,
  Popover,
  Checkbox
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  MinusOutlined,
  CloudDownloadOutlined
} from '@ant-design/icons';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import dayjs from 'dayjs';
import moment from 'moment';
import _ from 'lodash';
import { useRecoilValue, useRecoilState, useRecoilValueLoadable } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useJsonToCsv } from 'react-json-csv';
import api from '../../../../../../api/api';
import {
  dashboardSelectedStoreAtom,
  dashboardPayloadAtom,
  reloadTenderTypesTrendsAtom
} from '../../../../../../atoms/Atoms';
import {
  configSelector
} from '../../../../../../atoms/Selectors';
import { thousand, toCurrency, percent } from '../../../../../utils/functions';

function TenderTypeTrends({ className }) {
  const { saveAsCsv } = useJsonToCsv();
  const { t } = useTranslation();
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const [reload, setReload] = useRecoilState(reloadTenderTypesTrendsAtom);
  const selectedStoreId = useRecoilValue(dashboardSelectedStoreAtom);
  const [dashboardPayload, setDashboardPayload] =
    useRecoilState(dashboardPayloadAtom);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [dates, setDates] = useState({});
  const [compareLastYear, setCompareLastYear] = useState(false);
  const [trendsType, setTrendsType] = useState(false);
  const config = useRecoilValueLoadable(configSelector);
  const [tableColumns, setTableColumns] = useState([
    {
      title: t('tender_type'),
      key: 'name',
      align: 'center',
      className: 'text-xs',
      sorter: (a, b) => a.name - b.name,
      render: (row) => <span>{row.name}</span>,
    },
    {
      title: t('orders'),
      key: 'currentOrders',
      align: 'center',
      className: 'text-xs',
      sorter: (a, b) => a.currentOrders - b.currentOrders,
      render: (row) => (
        <div className="flex">
          <span>{thousand(row.currentOrders)}</span>
          {row.ordersGrowth === 0 && (
            <MinusOutlined
              className="ml-auto mt-1 flex-shrink-0 self-center h-5 w-5"
              aria-hidden="true"
            />
          )}
          {row.ordersGrowth > 0 && (
            <ArrowUpOutlined
              className="ml-auto mt-1 flex-shrink-0 self-center h-5 w-5 text-green-500"
              aria-hidden="true"
            />
          )}
          {row.ordersGrowth < 0 && (
            <ArrowDownOutlined
              className="ml-auto mt-1 flex-shrink-0 self-center h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          )}
          <span className={`${row.ordersGrowth < 0 ? 'text-red-500' : ''} ${row.ordersGrowth > 0 ? 'text-green-500' : ''}`}>{thousand(percent(row.ordersGrowth))}%</span>
        </div>
      ),
    },
    {
      exportOnly: true,
      key: 'ordersGrowth',
      hidden: false,
      title: t('orders_growth')
    },
    {
      title: t('amount_collected'),
      key: 'currentAmountCollected',
      align: 'center',
      className: 'text-xs',
      sorter: (a, b) => a.currentAmountCollected - b.currentAmountCollected,
      render: (row) => (
        <div className="flex">
          <span>{toCurrency(row.currentAmountCollected)}</span>
          {row.amountCollectedGrowth === 0 && (
            <MinusOutlined
              className="ml-auto mt-1 flex-shrink-0 self-center h-5 w-5"
              aria-hidden="true"
            />
          )}
          {row.amountCollectedGrowth > 0 && (
            <ArrowUpOutlined
              className="ml-auto mt-1 flex-shrink-0 self-center h-5 w-5 text-green-500"
              aria-hidden="true"
            />
          )}
          {row.amountCollectedGrowth < 0 && (
            <ArrowDownOutlined
              className="ml-auto mt-1 flex-shrink-0 self-center h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          )}
          <span className={`${row.amountCollectedGrowth < 0 ? 'text-red-500' : ''} ${row.avamountCollectedGrowtherageTicketGrowth > 0 ? 'text-green-500' : ''}`}>{thousand(percent(row.amountCollectedGrowth))}%</span>
        </div>
      ),
    },
    {
      exportOnly: true,
      key: 'amountCollectedGrowth',
      hidden: false,
      title: t('amount_collected_growth')
    },
    {
      title: t('refund_total'),
      key: 'currentRefundTotal',
      align: 'center',
      className: 'text-xs',
      sorter: (a, b) => a.currentRefundTotal - b.currentRefundTotal,
      render: (row) => (
        <div className="flex">
          <span>{toCurrency(row.currentRefundTotal)}</span>
          {row.refundTotalGrowth === 0 && (
            <MinusOutlined
              className="ml-auto mt-1 flex-shrink-0 self-center h-5 w-5"
              aria-hidden="true"
            />
          )}
          {row.refundTotalGrowth > 0 && (
            <ArrowUpOutlined
              className="ml-auto mt-1 flex-shrink-0 self-center h-5 w-5 text-green-500"
              aria-hidden="true"
            />
          )}
          {row.refundTotalGrowth < 0 && (
            <ArrowDownOutlined
              className="ml-auto mt-1 flex-shrink-0 self-center h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          )}
          <span className={`${row.refundTotalGrowth < 0 ? 'text-red-500' : ''} ${row.refundTotalGrowth > 0 ? 'text-green-500' : ''}`}>{thousand(percent(row.refundTotalGrowth))}%</span>
        </div>
      ),
    },
    {
      exportOnly: true,
      key: 'refundTotalGrowth',
      hidden: false,
      title: t('refund_total_growth')
    },
    {
      title: t('sales_total'),
      key: 'currentSalesTotal',
      align: 'center',
      className: 'text-xs',
      sorter: (a, b) => a.currentSalesTotal - b.currentSalesTotal,
      render: (row) => (
        <div className="flex">
          <span>{toCurrency(row.currentSalesTotal)}</span>
          {row.salesTotalGrowth === 0 && (
            <MinusOutlined
              className="ml-auto mt-1 flex-shrink-0 self-center h-5 w-5"
              aria-hidden="true"
            />
          )}
          {row.salesTotalGrowth > 0 && (
            <ArrowUpOutlined
              className="ml-auto mt-1 flex-shrink-0 self-center h-5 w-5 text-green-500"
              aria-hidden="true"
            />
          )}
          {row.salesTotalGrowth < 0 && (
            <ArrowDownOutlined
              className="ml-auto mt-1 flex-shrink-0 self-center h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          )}
          <span className={`${row.salesTotalGrowth < 0 ? 'text-red-500' : ''} ${row.salesTotalGrowth > 0 ? 'text-green-500' : ''}`}>{thousand(percent(row.salesTotalGrowth))}%</span>
        </div>
      ),
    },
    {
      exportOnly: true,
      key: 'salesTotalGrowth',
      hidden: false,
      title: t('sales_total_growth')
    },
    {
      title: t('average_ticket'),
      key: 'currentAverageTicket',
      align: 'center',
      className: 'text-xs',
      sorter: (a, b) => a.currentAverageTicket - b.currentAverageTicket,
      render: (row) => (
        <div className="flex">
          <span>{toCurrency(row.currentAverageTicket)}</span>
          {row.averageTicketGrowth === 0 && (
            <MinusOutlined
              className="ml-auto mt-1 flex-shrink-0 self-center h-5 w-5"
              aria-hidden="true"
            />
          )}
          {row.averageTicketGrowth > 0 && (
            <ArrowUpOutlined
              className="ml-auto mt-1 flex-shrink-0 self-center h-5 w-5 text-green-500"
              aria-hidden="true"
            />
          )}
          {row.averageTicketGrowth < 0 && (
            <ArrowDownOutlined
              className="ml-auto mt-1 flex-shrink-0 self-center h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          )}
          <span className={`${row.averageTicketGrowth < 0 ? 'text-red-500' : ''} ${row.averageTicketGrowth > 0 ? 'text-green-500' : ''}`}>{thousand(percent(row.averageTicketGrowth))}%</span>
        </div>
      ),
    },
    {
      exportOnly: true,
      key: 'averageTicketGrowth',
      hidden: false,
      title: t('average_ticket_growth')
    },
  ]);

  function toggleVisibility(key) {
    const tempColumns = [...tableColumns];
    for (let i = 0; i < tempColumns.length; i++) {
      if (tempColumns[i].key === key) {
        tempColumns[i].hidden = !tempColumns[i].hidden;
      }
    }
    setTableColumns(tempColumns);
  }

  function toggleComparisonVisibility(key) {
    const tempComparisons = [...data.comparisons];
    for (let i = 0; i < tempComparisons.length; i++) {
      if (tempComparisons[i].name === key) {
        tempComparisons[i].hidden = !tempComparisons[i].hidden;
      }
    }
    setData({
      ...data,
      comparisons: tempComparisons
    });
  }

  function getData() {
    setLoading(true);
    api
      .post('analytics/v2/trends/tendertypes', {
        CompareLastYear: compareLastYear,
        StartDate: moment(dates.start).utc(),
        EndDate: moment(dates.end).utc().add(1, 'days'),
        Type: trendsType,
        BusinessId: selectedStoreId
      })
      .then((response) => {
        setLoading(false);
        const temp = _.map(response.data.data.comparisons, (c) => ({
          ...c,
          hidden: false
        }));
        setData({
          ...response.data.data,
          comparisons: temp
        });
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      }).finally(() => {
        setReload(false);
      });
  }

  useEffect(() => {
    if (reload) {
      getData();
    }
  }, [reload]);

  useEffect(() => {
    setCompareLastYear(dashboardPayload.compareLastYear);
    setTrendsType(dashboardPayload.trendsType);
  }, [dashboardPayload.compareLastYear, dashboardPayload.trendsType]);

  useEffect(() => {
    const temp = {
      start: dashboardPayload.start,
      end: dashboardPayload.end
    };
    setDates(temp);
  }, [dashboardPayload.start, dashboardPayload.end]);

  useEffect(() => {
    const tempColumns = [...tableColumns];
    for (let i = 0; i < tempColumns.length; i++) {
      tempColumns[i].hidden = false;
    }
    setTableColumns(tempColumns);
  }, [showModal]);

  return (
    <Card
      className={`shadow-lg ${className}`}
      hoverable
    >
      <Spin spinning={loading}>
        <PageHeader
          className="-mt-4 p-0"
          title={t('tender_types')}
        />
        <div className="col-span-1 h-96">
          <Bar
            height={350}
            data={{
              labels: _.map(data?.comparisons, (s) => s.name),
              datasets: [
                {
                  label: data?.previousLabel,
                  borderColor: config?.contents?.data?.secondaryColor,
                  backgroundColor: `${config?.contents?.data?.secondaryColor}cc`,
                  data: _.map(data?.comparisons, (x) => x.previousAmountCollected),
                },
                {
                  label: data?.currentLabel,
                  borderColor: config?.contents?.data?.primaryColor,
                  backgroundColor: `${config?.contents?.data?.primaryColor}cc`,
                  data: _.map(data?.comparisons, (x) => x.currentAmountCollected),
                },
              ],
            }}
            plugins={[ChartDataLabels]}
            options={{
              maintainAspectRatio: false,
              scales: {
                yAxes: [{
                  ticks: {
                    beginAtZero: true,
                    callback: (value, index, ticks) => toCurrency(value)
                  }
                }],
              },
              responsive: true,
              plugins: {
                legend: {
                  position: 'top',
                },
                title: {
                  display: false,
                },
                datalabels: {
                  anchor: 'end',
                  offset: 8,
                  rotation: 330,
                  color: 'black',
                  formatter: (value, context) => toCurrency(value),
                  labels: {
                    title: {
                      font: {
                        weight: 'regular'
                      }
                    }
                  }
                }
              },
            }}
          />
        </div>
        <Button
          className="-mb-4 float-right text-blue-500 border-none"
          onClick={() => setShowModal(true)}
        >
          <span className="underline">{t('view_details')}</span>
        </Button>
      </Spin>
      <Modal
        title={t('tender_types')}
        open={showModal}
        centered
        width="85%"
        onCancel={() => setShowModal(false)}
        footer={[
          <Button
            key="close"
            type="primary"
            danger
            onClick={() => setShowModal(false)}
          >
            {t('close')}
          </Button>
        ]}
      >
        <div className="space-y-4">
          <div className="grid ml-8 md:ml-0 md:flex md:space-x-2 space-y-2">
            <Select
              className="ml-auto my-auto pt-2"
              value={trendsType}
              style={{ width: 160 }}
              onChange={(obj) => setTrendsType(obj)}
            >
              <Option value={0}>{t('all_orders')}</Option>
              <Option value={1}>{t('webstore')}</Option>
              <Option value={2}>{t('pos')}</Option>
              <Option value={3}>{t('self_checkout')}</Option>
            </Select>
            <RangePicker
              format="YYYY-MM-DD"
              value={[
                dayjs(dates?.start),
                dayjs(dates?.end)
              ]}
              presets={[
                {
                  label: t('today'),
                  value: () => [moment(), moment()]
                },
                {
                  label: t('yesterday'),
                  value: () => [moment().add(-1, 'day'), moment().add(-1, 'day')]
                },
                {
                  label: t('current_week'),
                  value: () => [moment().startOf('week'), moment().endOf('week')]
                },
                {
                  label: t('last_week'),
                  value: () => [moment().add(-1, 'week').startOf('week'), moment().add(-1, 'week').endOf('week')]
                },
                {
                  label: t('current_month'),
                  value: () => [moment().startOf('month'), moment().endOf('month')]
                },
                {
                  label: t('past_month'),
                  value: () => [moment().add(-1, 'months').startOf('month'), moment().add(-1, 'months').endOf('month')]
                },
                {
                  label: t('last_2_month'),
                  value: () => [moment().add(-1, 'month').startOf('month'), moment().endOf('month')]
                },
                {
                  label: t('last_3_month'),
                  value: () => [moment().add(-2, 'month').startOf('month'), moment().endOf('month')]
                },
                {
                  label: t('last_4_month'),
                  value: () => [moment().add(-3, 'month').startOf('month'), moment().endOf('month')]
                },
                {
                  label: t('last_6_month'),
                  value: () => [moment().add(-5, 'month').startOf('month'), moment().endOf('month')]
                },
                {
                  label: t('year_to_date'),
                  value: () => [moment().startOf('year'), moment()]
                },
                {
                  label: t('last_year'),
                  value: () => [moment().add(-1, 'year').startOf('year'), moment().add(-1, 'year').endOf('year')]
                },
              ]}
              onChange={(date, dateString) => {
                const temp = {
                  start: dateString[0],
                  end: dateString[1]
                };
                setDates(temp);
              }}
            />
            <div className="mt-1 mx-auto md:mx-1">
              <span className="mt-2 md:ml-1">{t('compare_with_previous_year')}</span>
              <Switch
                className="md:ml-1"
                checked={compareLastYear}
                onChange={(checked) => setCompareLastYear(checked)}
              />
            </div>
            <Button
              type="primary"
              size="small"
              loading={loading}
              onClick={() => getData()}
            >
              {t('reload')}
            </Button>
          </div>
          <Spin spinning={loading}>
            <div style={{ height: 300 }}>
              <Bar
                data={{
                  labels: _.map(_.filter(data?.comparisons, (c) => !c.hidden), (s) => s.name),
                  datasets: [
                    {
                      label: data?.previousLabel,
                      borderColor: config?.contents?.data?.secondaryColor,
                      backgroundColor: `${config?.contents?.data?.secondaryColor}cc`,
                      data: _.map(_.filter(data?.comparisons, (c) => !c.hidden), (x) => x.previousAmountCollected),
                    },
                    {
                      label: data?.currentLabel,
                      borderColor: config?.contents?.data?.primaryColor,
                      backgroundColor: `${config?.contents?.data?.primaryColor}cc`,
                      data: _.map(_.filter(data?.comparisons, (c) => !c.hidden), (x) => x.currentAmountCollected),
                    },
                  ],
                }}
                plugins={[ChartDataLabels]}
                options={{
                  maintainAspectRatio: false,
                  scales: {
                    yAxes: [{
                      ticks: {
                        beginAtZero: true,
                        callback: (value, index, ticks) => toCurrency(value)
                      }
                    }],
                  },
                  responsive: true,
                  plugins: {
                    legend: {
                      position: 'top',
                    },
                    title: {
                      display: false,
                    },
                    datalabels: {
                      anchor: 'end',
                      offset: 8,
                      rotation: 330,
                      color: 'black',
                      formatter: (value, context) => toCurrency(value),
                      labels: {
                        title: {
                          font: {
                            weight: 'regular'
                          }
                        }
                      }
                    }
                  },
                }}
              />
            </div>
            <Table
              bordered
              size="small"
              dataSource={_.filter(data?.comparisons, (c) => !c.hidden)}
              columns={_.filter(tableColumns, (c) => !c.exportOnly)}
              title={() => (
                <div className="flex space-x-2">
                  <Popover
                    className=""
                    trigger="click"
                    content={(
                      <div className="grid grid-cols-4 gap-4">
                        {_.map(_.filter(tableColumns, (c) => !c.exportOnly), (c) => (
                          <Checkbox
                            checked={!c.hidden}
                            onChange={(value) => toggleVisibility(c.key)}
                          >
                            {c.title}
                          </Checkbox>
                        ))}
                      </div>
                    )}
                  >
                    <Button className="ml-auto font-bold text-blue-500 hover:text-blue-800 border-none bg-transparent">
                      {t('show_hide_columns')}
                    </Button>
                  </Popover>
                  <Popover
                    className=""
                    trigger="click"
                    content={(
                      <div className="grid grid-cols-5 gap-2">
                        {_.map(data?.comparisons, (c) => (
                          <Checkbox
                            checked={!c.hidden}
                            onChange={(value) => toggleComparisonVisibility(c.name)}
                          >
                            {c.name}
                          </Checkbox>
                        ))}
                      </div>
                    )}
                  >
                    <Button className="ml-auto font-bold text-blue-500 hover:text-blue-800 border-none bg-transparent">
                      {t('show_hide')} {t('tender_types')}
                    </Button>
                  </Popover>
                  <Button
                    className="mt-1"
                    size="small"
                    icon={<CloudDownloadOutlined />}
                    onClick={() => {
                      const shownColumns = _.filter(tableColumns, (c) => !c.hidden);
                      const tempFields = { };
                      for (let i = 0; i < shownColumns.length; i++) {
                        tempFields[shownColumns[i].key] = shownColumns[i].title;
                      }
                      saveAsCsv({
                        data: _.filter(data.comparisons, (c) => !c.hidden),
                        fields: tempFields,
                        filename: `tenderTypeTrends_${dates.start}_${dates.end}`,
                      });
                    }}
                  >
                    {t('export_csv')}
                  </Button>
                </div>
              )}
            />
          </Spin>
        </div>
      </Modal>
    </Card>
  );
}

export default TenderTypeTrends;
