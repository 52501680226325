import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Table,
  Modal,
  Switch,
  DatePicker,
  Descriptions,
  Input,
  Select
} from 'antd';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import moment from 'moment';
import { PageHeader } from '@ant-design/pro-layout';
import { useRecoilValue } from 'recoil';
import dayjs from 'dayjs';
import {
  businessAtom, timezoneAtom
} from '../../../atoms/Atoms';
import api from '../../../api/api';
import envConfig from '../../../envConfig';
import { renderDate, toCurrency } from '../../utils/functions';

function POSCashDrawerCheckIn() {
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const { t } = useTranslation();
  const history = useHistory();
  const business = useRecoilValue(businessAtom);
  const timezone = useRecoilValue(timezoneAtom);
  const [loading, setLoading] = useState(false);
  const [loadingLanes, setLoadingLanes] = useState(false);
  const [loadingEmployees, setLoadingEmployees] = useState(false);
  const [dateRange, setDateRange] = useState({
    start: moment().startOf('day').format('YYYY-MM-DD hh:mm A'),
    end: moment().endOf('day').format('YYYY-MM-DD hh:mm A')
  });
  const [reportData, setReportData] = useState(null);
  const [posLanes, setPosLanes] = useState([]);
  const [employee, setEmployees] = useState([]);
  const [includeOrders, setIncludeOrders] = useState(false);

  const [showOver, setShowOver] = useState(false);
  const [showUnder, setShowUnder] = useState(false);
  const [showClosed, setShowClosed] = useState(false);
  const [showOpen, setShowOpen] = useState(false);

  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [selectedPosLane, setSelectedPosLane] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [size, setSize] = useState(20);
  const [totalItemsInSearch, setTotalItemsInSearch] = useState(0);
  const [reload, setReload] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [selectedCheckIn, setSelectedCheckIn] = useState(null);
  const [showAddComment, setShowAddComment] = useState(false);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');

  function getComments(id) {
    setLoading(true);
    api
      .get(`pos/cashdrawer/checkin/comments/${id}`)
      .then((response) => {
        setLoading(false);
        setComments(response.data.data.results);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }

  function submitComment() {
    setLoading(true);
    api
      .post('pos/cashdrawer/checkin/comments', {
        CheckInId: selectedCheckIn.id,
        Comment: newComment
      })
      .then((response) => {
        setReload(true);
        setLoading(false);
        setShowAddComment(false);
        setComments(response.data.data.results);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }

  function getPosLanes() {
    setLoadingLanes(true);
    api
      .get(`pos/manage/${business?.id}`)
      .then((response) => {
        setLoadingLanes(false);
        setPosLanes(response.data.data.results);
      })
      .catch((error) => {
        setLoadingLanes(false);
        console.error(error);
      });
  }

  function getEmployees() {
    setLoadingEmployees(true);
    api
      .get(`businesses/${business.id}/team`)
      .then((response) => {
        setEmployees(_.orderBy(_.filter(response.data.data.results, (s) => s.isActive), (e) => e.completeName));
        setLoadingEmployees(false);
      })
      .catch((error) => {
        setLoadingEmployees(false);
        console.error(error);
      });
  }

  function search(page) {
    setReload(false);
    setLoading(true);
    const data = {
      StartDate: moment(dateRange.start)
        .add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hour')
        .format('YYYY-MM-DDTHH:mm:ss'),
      EndDate: moment(dateRange.end)
        .add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hour')
        .format('YYYY-MM-DDTHH:mm:ss'),
      BusinessId: business?.id,
      PosLaneId: selectedPosLane,
      EmployeeId: selectedEmployee,
      IncludeMovement: includeOrders,
      OnlyClosed: showClosed,
      OnlyOpen: showOpen,
      OnlyUnder: showUnder,
      OnlyOver: showOver,
      Page: page,
      Size: size
    };
    api
      .post('pos/cashdrawer/movement/report', data)
      .then((response) => {
        setLoading(false);
        setReportData(response.data.data.checkIns);
        setTotalItemsInSearch(response.data.data.total);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  const onTableChange = (page, pageSize) => {
    if (page - 1 !== currentPage) {
      setCurrentPage(page - 1);
      search(page - 1);
    } else {
      setSize(pageSize);
    }
  };

  if (!business) {
    history.push('/');
    return null;
  }

  useEffect(() => {
    if (!selectedCheckIn) {
      search(currentPage);
    }
  }, [selectedCheckIn]);

  useEffect(() => {
    if (!showComments) {
      setComments([]);
    }
  }, [showComments]);

  useEffect(() => {
    if (!showAddComment) {
      setNewComment('');
    }
  }, [showAddComment]);

  useEffect(() => {
    if (showOpen) {
      setShowOpen(false);
    }
  }, [showUnder, showOver, showClosed]);

  useEffect(() => {
    if (showOver) {
      setShowOver(false);
    }
    if (showClosed) {
      setShowClosed(false);
    }
    if (showUnder) {
      setShowUnder(false);
    }
  }, [showOpen]);

  useEffect(() => {
    if (showOver) {
      setShowOver(false);
    }
  }, [showUnder]);

  useEffect(() => {
    if (showUnder) {
      setShowUnder(false);
    }
  }, [showOver]);

  useEffect(() => {
    getPosLanes();
    getEmployees();
    setSelectedEmployee('');
    setSelectedPosLane('');
    setTotalItemsInSearch(0);
    setCurrentPage(0);
  }, [business]);

  useEffect(() => {
    setCurrentPage(0);
    search(0);
  }, [size, includeOrders, selectedEmployee, selectedPosLane, dateRange]);

  return (
    <>
      <PageHeader
        className="mb-4 px-0"
        title={t('cash_drawer_report')}
        extra={[
          <div key="open" style={{ marginTop: '-4px' }}>
            <strong>{t('open_only')}</strong>
            <br />
            <Switch
              style={{ marginTop: '5px' }}
              disabled={loading}
              checked={showOpen}
              onChange={(checked) => setShowOpen(checked)}
            />
          </div>,
          <div key="closed" style={{ marginTop: '-4px' }}>
            <strong>{t('closed_only')}</strong>
            <br />
            <Switch
              style={{ marginTop: '5px' }}
              disabled={loading}
              checked={showClosed}
              onChange={(checked) => setShowClosed(checked)}
            />
          </div>,
          <div key="under" style={{ marginTop: '-4px' }}>
            <strong>{t('under_only')}</strong>
            <br />
            <Switch
              style={{ marginTop: '5px' }}
              disabled={loading}
              checked={showUnder}
              onChange={(checked) => setShowUnder(checked)}
            />
          </div>,
          <div key="over" style={{ marginTop: '-4px' }}>
            <strong>{t('over_only')}</strong>
            <br />
            <Switch
              style={{ marginTop: '5px' }}
              disabled={loading}
              checked={showOver}
              onChange={(checked) => setShowOver(checked)}
            />
          </div>,
          <div key="movement" style={{ marginTop: '-4px' }}>
            <strong>{t('include_movement')}</strong>
            <br />
            <Switch
              style={{ marginTop: '5px' }}
              disabled={loading}
              checked={includeOrders}
              onChange={(checked) => setIncludeOrders(checked)}
            />
          </div>,
          <div key="lane">
            <strong>{t('pos_lane')}</strong>
            <br />
            <Select
              style={{ width: 150 }}
              disabled={loadingLanes || loading}
              onChange={(value) => setSelectedPosLane(value)}
              value={selectedPosLane}
            >
              <Option key="any" value="" className="text-xs flex items-center">
                {t('any')}
              </Option>
              {_.map(posLanes, (l) => (
                <Option
                  key={l.id}
                  value={l.id}
                  className="text-xs flex items-center"
                >
                  {l.tag}
                </Option>
              ))}
            </Select>
          </div>,
          <div key="employee">
            <strong>{t('employee')}</strong>
            <br />
            <Select
              disabled={loadingEmployees || loading}
              style={{ width: 150 }}
              onChange={(value) => setSelectedEmployee(value)}
              value={selectedEmployee}
            >
              <Option key="any" value="" className="text-xs flex items-center">
                {t('any')}
              </Option>
              {_.map(employee, (l) => (
                <Option
                  key={l.userId}
                  value={l.userId}
                  className="text-xs flex items-center"
                >
                  {l.completeName}
                </Option>
              ))}
            </Select>
          </div>,
          <RangePicker
            key="dates"
            className="mt-5"
            disabled={loading}
            format="YYYY-MM-DD hh:mm A"
            showTime
            presets={[
              {
                label: t('today'),
                value: () => [moment().startOf('day'), moment().endOf('day')]
              },
              {
                label: t('yesterday'),
                value: () => [moment().add(-1, 'day').startOf('day'), moment().add(-1, 'day').endOf('day')]
              },
              {
                label: t('current_week'),
                value: () => [moment().startOf('week'), moment().endOf('week')]
              },
              {
                label: t('last_week'),
                value: () => [moment().add(-1, 'week').startOf('week'), moment().add(-1, 'week').endOf('week')]
              },
              {
                label: t('current_month'),
                value: () => [moment().startOf('month'), moment().endOf('month')]
              },
              {
                label: t('last_month'),
                value: () => [moment().add(-1, 'month').startOf('month'), moment().add(-1, 'month').endOf('month')]
              },
            ]}
            value={[
              dayjs(dateRange.start, 'YYYY-MM-DD hh:mm A'),
              dayjs(dateRange.end, 'YYYY-MM-DD hh:mm A')
            ]}
            onChange={(date, dateString) => {
              setDateRange({
                start: dateString[0],
                end: dateString[1]
              });
            }}
          />,
          <Button
            id="reload"
            type="primary"
            key="reload"
            className="mt-5"
            size="small"
            loading={loading}
            disabled={loading}
            onClick={() => search()}
          >
            {t('reload')}
          </Button>
        ]}
      />
      <Table
        rowKey="id"
        bordered
        dataSource={reportData}
        loading={loading}
        pagination={{
          pageSize: size,
          defaultCurrent: 0,
          current: currentPage + 1,
          total: totalItemsInSearch,
          onChange: onTableChange
        }}
        columns={[
          {
            title: t('employee'),
            key: 'employee',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{row.user?.completeName}</span>
            ),
          },
          {
            title: t('pos_lane'),
            key: 'lane',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{row.posLane.tag}</span>
            ),
          },
          {
            title: t('start'),
            key: 'startdate',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <>
                <span>{renderDate(row.startTime, timezone)}</span>
              </>
            ),
          },
          {
            title: t('end'),
            key: 'enddate',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{row.endTime ? renderDate(row.endTime, timezone) : '-'}</span>
            ),
          },
          {
            title: t('opening_amount'),
            key: 'startamount',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{toCurrency(row.startAmount)}</span>
            ),
          },
          {
            title: t('sales'),
            key: 'dropInTotal',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>
                {toCurrency(row.profit)}
              </span>
            ),
          },
          {
            title: t('drops'),
            key: 'dropInTotal',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>
                {toCurrency(row.dropInTotal)}
              </span>
            ),
          },
          {
            title: t('calculated_balance'),
            key: 'balance',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>
                {toCurrency(row.balance)}
              </span>
            ),
          },
          {
            title: t('closing_amount'),
            key: 'endamount',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <>
                {row.endAmount && (
                  <>
                    <span className={`font-bold ${row.endAmount !== row.calculatedEndAmount ? 'text-red-400' : 'text-green-400'}`}>
                      {toCurrency(row.endAmount)}
                    </span>
                  </>
                )}
              </>
            ),
          },
          {
            title: t('difference'),
            key: 'endamount',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <>
                {row.endAmount && (
                  <>
                    <span>
                      {toCurrency(row.endAmount - row.calculatedEndAmount)}
                    </span>
                  </>
                )}
              </>
            ),
          },
          {
            title: t('actions'),
            key: 'actions',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    setShowComments(true);
                    setSelectedCheckIn(row);
                    getComments(row.id);
                  }}
                >
                  {`${t('comments')} (${row.commentsCount})`}
                </Button>
              </span>
            ),
          },
        ]}
        expandable={{
          expandRowByClick: true,
          rowExpandable: (record) => includeOrders,
          expandedRowRender: (record) => (
            <>
              {/* <Descriptions bordered column={3}>
                <DescriptionsItem span={1}>
                  <strong>{t('difference')}: </strong>
                  <span>{toCurrency(record.endAmount - record.calculatedEndAmount)}</span>
                </DescriptionsItem>
                <DescriptionsItem span={1}>
                  <strong>{t('net')}: </strong>
                  <span>{toCurrency(record?.net)}</span>
                </DescriptionsItem>
                <DescriptionsItem span={1}>
                  <strong>{t('profit')}: </strong>
                  <span>{toCurrency(record?.profit)}</span>
                </DescriptionsItem>
              </Descriptions> */}
              {record.movement?.length > 0 && (
                <Table
                  size="small"
                  bordered
                  rowKey="id"
                  dataSource={record.movement}
                  columns={[
                    {
                      title: t('created_at'),
                      key: 'createdAt',
                      align: 'center',
                      className: 'text-xs',
                      render: (row) => (
                        <span>{moment(row.createdAt).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hour').format('YYYY-MM-DD hh:mm A')}</span>
                      ),
                    },
                    {
                      title: t('is_drop_in'),
                      key: 'is_drop_in',
                      align: 'center',
                      className: 'text-xs',
                      render: (row) => (
                        <span>{row.isDropIn ? t('yes') : t('no')}</span>
                      ),
                    },
                    {
                      title: t('cash_in'),
                      key: 'cash_in',
                      align: 'center',
                      className: 'text-xs',
                      render: (row) => (
                        <span>{toCurrency(row.received)}</span>
                      ),
                    },
                    {
                      title: t('cash_out'),
                      key: 'cashout',
                      align: 'center',
                      className: 'text-xs',
                      render: (row) => (
                        <span>{toCurrency(row.returned)}</span>
                      ),
                    },
                    {
                      title: t('comments'),
                      key: 'comments',
                      align: 'center',
                      className: 'text-xs',
                      render: (row) => (
                        <span>{row.comments ? row.comments : '-'}</span>
                      ),
                    },
                    {
                      title: t('order'),
                      key: 'order',
                      align: 'center',
                      className: 'text-xs',
                      render: (row) => (
                        <span>{row.order ? row.order.confirmationCode : '-'}</span>
                      ),
                    }
                  ]}
                />
              )}
            </>
          )
        }}
      />

      <Modal
        title={t('comments')}
        open={showComments}
        onCancel={() => {
          setShowComments(false);
          setSelectedCheckIn(null);
        }}
        footer={[
          <Button
            key="close"
            type="primary"
            danger
            loading={loading}
            onClick={() => {
              setShowComments(false);
              setSelectedCheckIn(null);
            }}
          >
            {t('close')}
          </Button>,
          <Button
            key="ok"
            type="primary"
            loading={loading}
            onClick={() => setShowAddComment(true)}
          >
            {t('add_comment')}
          </Button>
        ]}
      >
        <Table
          size="small"
          bordered
          loading={loading}
          dataSource={comments}
          columns={[
            {
              title: t('created_at'),
              key: 'created_at',
              align: 'center',
              className: 'text-xs',
              render: (row) => (
                <span>{moment(row.createdAt).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hour').format('YYYY-MM-DD hh:mm A')}</span>
              ),
            },
            {
              title: t('user'),
              key: 'user',
              align: 'center',
              className: 'text-xs',
              render: (row) => (
                <div className="flex space-x-2">
                  <img
                    src={row.userAvatar}
                    alt={row.userCompleteName}
                    className="rounded-xl"
                    width={25}
                  />
                  <span className="mt-1">{row.userCompleteName}</span>
                </div>
              ),
            },
            {
              title: t('comment'),
              key: 'comment',
              align: 'center',
              className: 'text-xs',
              render: (row) => (
                <span>{row.comments}</span>
              ),
            },
          ]}
        />
      </Modal>

      <Modal
        title={t('new_comment')}
        open={showAddComment}
        onCancel={() => {
          setShowAddComment(false);
        }}
        footer={[
          <Button
            key="close"
            type="primary"
            danger
            loading={loading}
            onClick={() => {
              setShowAddComment(false);
            }}
          >
            {t('close')}
          </Button>,
          <Button
            key="ok"
            type="primary"
            loading={loading}
            onClick={() => submitComment()}
          >
            {t('submit')}
          </Button>
        ]}
      >
        <Input
          className="w-full"
          value={newComment}
          onChange={(obj) => {
            setNewComment(obj.target.value);
          }}
          disabled={loading}
        />
      </Modal>
    </>
  );
}

export default POSCashDrawerCheckIn;
