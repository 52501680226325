import _ from 'lodash';
import moment from 'moment';
import visa from '../../assets/images/credit-cards/visa.svg';
import amex from '../../assets/images/credit-cards/amex.svg';
import dinners from '../../assets/images/credit-cards/dinners.svg';
import mastercard from '../../assets/images/credit-cards/master.svg';
import discover from '../../assets/images/credit-cards/discover.svg';
import unknown from '../../assets/images/credit-cards/unknown.svg';

export const formatPhoneNumber = (phoneNumberString) => {
  if (phoneNumberString) {
    const cleaned = phoneNumberString.replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
  }
  return phoneNumberString;
};

export const calculateContribution = (total, contribution) => {
  if ((contribution / total) === Infinity) {
    return 0.00;
  }
  return `${((contribution / total) * 100).toFixed(2)}%`;
};

export const addCommas = (number) => `$${number
  .toFixed(2)
  .toString()
  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;

export const thousand = (n) => {
  try {
    let number = n;
    if (number === null || number === undefined) {
      return '0';
    }
    if (!number) {
      return '0';
    }
    if (typeof number !== 'number') {
      number = parseFloat(n);
    }
    if (number) {
      number = parseFloat(number.toString().replace(',', ''));
      if (number % 1 === 0) {
        number = number.toFixed(0);
      } else {
        number = number.toFixed(2);
      }
      const parts = number.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      const result = parts.join('.');
      return result;
    }
    return '0';
  } catch (error) {
    return '0';
  }
};

export const thousandv2 = (n, removeDecimals) => {
  try {
    let number = n;
    if (number === null || number === undefined) {
      return '0';
    }
    if (!number) {
      return '0';
    }
    if (typeof number !== 'number') {
      number = parseFloat(n);
    }
    if (number) {
      number = parseFloat(number.toString().replace(',', ''));
      number = number.toFixed(2);
      const parts = number.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      if (!removeDecimals) {
        const result = parts.join('.');
        return result;
      }
      return parts[0];
    }
    return '0';
  } catch (error) {
    return '0';
  }
};

export const percent = (value) => {
  if (value) {
    return thousand((value * 100).toFixed(2));
  }
  return '0';
};

export const toCurrency = (inNumber, removeDecimals) => {
  try {
    let number = inNumber;
    if (!number) {
      return '-';
    }
    if (typeof number !== 'number') {
      number = parseFloat(inNumber);
    }
    const wasNegative = number < 0;
    let fixed = 0;
    if (wasNegative) {
      fixed = (-1 * number).toFixed(2);
      // console.log(fixed);
    } else {
      fixed = number.toFixed(2);
    }
    if (wasNegative) {
      return `-$${thousandv2(fixed, removeDecimals)}`;
    }
    return `$${thousandv2(fixed, removeDecimals)}`;
  } catch (error) {
    return '-';
  }
};

export const mapSubscriptionBenefitType = (type) => {
  if (type === 0) {
    return 'fixed_discount';
  }
  if (type === 1) {
    return 'percent_discount';
  }
  if (type === 2) {
    return 'special_price';
  }
  if (type === 3) {
    return 'free';
  }
  return '';
};

export const mapSubscriptionRecurrence = (recurrence) => {
  if (recurrence === 0) {
    return 'none';
  }
  if (recurrence === 1) {
    return 'daily';
  }
  if (recurrence === 2) {
    return 'weekly';
  }
  if (recurrence === 3) {
    return 'biweekly';
  }
  if (recurrence === 4) {
    return 'monthly';
  }
  if (recurrence === 5) {
    return 'bimonthly';
  }
  if (recurrence === 6) {
    return 'quarterly';
  }
  if (recurrence === 7) {
    return 'semiyearly';
  }
  if (recurrence === 8) {
    return 'yearly';
  }
  return '';
};

export const getCardType = (card) => {
  switch (card) {
    case 'Visa':
      return visa;
    case 'Amex':
      return 'amex';
    case 'American Express':
      return amex;
    case 'MasterCard':
      return mastercard;
    case 'Discover':
      return discover;
    case 'Dinner':
      return dinners;
    default:
      return unknown;
  }
};

export const getUserPlatform = (platform) => {
  switch (platform) {
    case 0:
      return 'iOS';
    case 1:
      return 'Android';
    default:
      return 'Web';
  }
};

export const getLanguage = (lang) => {
  switch (lang) {
    case 0:
      return 'spanish';
    case 1:
      return 'english';
    default:
      return 'unknown';
  }
};

function prepareDate(date, timezone) {
  if (date) {
    const dateString = date.toString();
    if (dateString.includes('Z') && dateString.includes('T')) {
      return moment.utc(`${date.replace('T', ' ').replace('Z', '')}+0000`)
        .tz(timezone);
    }
    return moment.utc(date).tz(timezone);
  }
  return null;
}

export const renderDate = (date, timezone) => {
  try {
    if (date) {
      const momentDate = prepareDate(date, timezone);
      return momentDate.isValid() ? momentDate.format('ddd MMMM Do, YYYY hh:mm a') : date;
    }
    return '';
  } catch (error) {
    console.log(error);
    return date;
  }
};

export const renderShortDate = (date, timezone) => {
  try {
    if (date) {
      const momentDate = prepareDate(date, timezone);
      return momentDate.isValid() ? momentDate.format('ddd MMM Do, YYYY') : date;
    }
    return '';
  } catch (error) {
    console.log(error);
    return date;
  }
};

export const renderShortDateTime = (date, timezone) => {
  try {
    if (date) {
      const momentDate = prepareDate(date, timezone);
      return momentDate.isValid() ? momentDate.format('MMM D, YY h:mm:ss a') : date;
    }
    return '';
  } catch (error) {
    return date;
  }
};

export const renderShortDateHour = (date, timezone) => {
  try {
    if (date) {
      const momentDate = prepareDate(date, timezone);
      return momentDate.isValid() ? momentDate.format('MMM D, YY h:mm A') : date;
    }
    return '';
  } catch (error) {
    return date;
  }
};

export const renderHour = (date, timezone) => {
  try {
    if (date) {
      const momentDate = prepareDate(date, timezone);
      return momentDate.isValid() ? momentDate.format('h:mm A') : date;
    }
    return '';
  } catch (error) {
    return date;
  }
};

export const toShortDate = (date, timezone) => {
  if (date) {
    return renderShortDate(date, timezone);
  }
  return '';
};

export const mapOrderStatus = (status) => {
  switch (status) {
    case 0:
      return 'status_placed';
    case 2:
      return 'status_delivery_assigned';
    case 3:
      return 'status_accepted';
    case 4:
      return 'status_ready';
    case 6:
      return 'status_customer_picked_up';
    case 7:
      return 'status_delivered';
    case 8:
      return 'status_store_cancelled';
    case 9:
      return 'status_user_cancelled';
    case 10:
      return 'status_declined';
    case 5:
      return 'status_delivery_started';
    case 16:
      return 'status_picking_started';
    case 17:
      return 'status_picking_finished';
    case 18:
      return 'status_packing_started';
    case 20:
      return 'status_ready_for_pickup';
    case 21:
      return 'status_pending_delivery_assignment';
    case 27:
      return 'status_pending_paypal_payment';
    case 30:
      return 'status_pending_payment';
    case 32:
      return 'status_under_review';
    case 33:
      return 'status_ivu_control_pending';
    default:
      return 'status_unknown';
  }
};

export const mapOrderType = (type) => {
  switch (type) {
    case 0:
      return 'pickup';
    case 1:
      return 'delivery';
    case 5:
      return 'in-store';
    case 6:
      return 'deli_pickup';
    case 7:
      return 'deli_delivery';
    default:
      return 'unknown';
  }
};

export const mapGender = (gender) => {
  switch (gender) {
    case 0:
      return 'male';
    case 1:
      return 'female';
    case 2:
      return 'unknown';
    default:
      return gender;
  }
};

export const mapPaymentType = (type) => {
  switch (type) {
    case 0:
      return 'Stripe';
    case 1:
      return 'ATH Movil';
    case 2:
      return 'cash';
    case 3:
      return 'other';
    case 4:
      return 'EBT';
    case 5:
      return 'PayPal';
    case 6:
      return 'cheque';
    case 7:
      return 'ATH';
    case 8:
      return 'vanilla_card';
    case 9:
      return 'gift_card';
    case 10:
      return 'MCS';
    case 11:
      return 'MMM';
    case 12:
      return 'Accepta';
    case 13:
      return 'net_30';
    case 14:
      return 'net_60';
    case 15:
      return 'not_selected';
    case 16:
      return 'credit_card';
    case 17:
      return 'debit_card';
    case 18:
      return 'SSS';
    case 19:
      return 'Humana';
    case 20:
      return 'BrainTree';
    case 21:
      return 'in_store';
    case 22:
      return 'prepaid';
    case 23:
      return 'ebt_cash';
    case 24:
      return 'Unica';
    default:
      return type;
  }
};

export const weekday = (day) => {
  switch (day) {
    case 0:
      return 'sunday';
    case 1:
      return 'monday';
    case 2:
      return 'tuesday';
    case 3:
      return 'wednesday';
    case 4:
      return 'thursday';
    case 5:
      return 'friday';
    case 6:
      return 'saturday';
    default:
      return 'unknown';
  }
};

export const booleanConvert = (val) => {
  if (val === null || val === undefined) {
    return 'null';
  }
  if (val) {
    return 'yes';
  }
  return 'no';
};

export const convertTaxRule = (rule) => {
  switch (rule) {
    case 0:
      return 'no_tax';
    case 1:
      return 'municipal_only';
    case 2:
      return 'state_only';
    case 3:
      return 'municipal_and_state';
    default:
      return 'unknown';
  }
};
export const monthToString = (month) => {
  switch (month) {
    case '1':
      return 'JAN';
    case '2':
      return 'FEB';
    case '3':
      return 'MAR';
    case '4':
      return 'APR';
    case '5':
      return 'MAY';
    case '6':
      return 'JUN';
    case '7':
      return 'JUL';
    case '8':
      return 'AUG';
    case '9':
      return 'SEP';
    case '10':
      return 'OCT';
    case '11':
      return 'NOV';
    case '12':
      return 'DEC';
    default:
      return 'unknown';
  }
};

export const cardFundingString = (type) => {
  switch (type) {
    case 0:
      return 'credit';
    case 1:
      return 'debit';
    case 2:
      return 'prepaid';
    default:
      return 'unknown';
  }
};

export const convertPriceMethod = (method) => {
  switch (method) {
    case 0:
      return 'unit_pricing';
    case 1:
      return 'split_package';
    default:
      return 'unknown';
  }
};

export const convertTimeFactor = (timeFactor) => {
  switch (timeFactor) {
    case 0:
      return 'day';
    case 1:
      return 'week';
    case 2:
      return 'month';
    case 4:
      return 'year';
    default:
      return 'unknown';
  }
};

export const dataURItoBlob = (dataURI) => {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
};

export const randomColor = () => {
  let color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  while (color === '#000000' || color === '#ffffff') {
    color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  }
  return color;
};

export const logType = (log) => {
  switch (log) {
    case 0:
      return 'error';
    case 1:
      return 'item_update';
    case 2:
      return 'order';
    case 3:
      return 'stripe_payment';
    case 4:
      return 'ath_movil_payment';
    case 5:
      return 'pos_order_fetch';
    case 6:
      return 'missing_item';
    case 7:
      return 'stripe_connect';
    case 8:
      return 'stripe_account';
    case 9:
      return 'stripe_transfer';
    case 10:
      return 'mbs_log';
    case 11:
      return 'stripe_notification';
    case 12:
      return 'return';
    case 13:
      return 'stripe_daily_transfer';
    case 14:
      return 'stripe_monthly_transfer';
    case 15:
      return 'marketing';
    case 16:
      return 'ivu_control_number_update';
    case 17:
      return 'api_security_key_error';
    case 18:
      return 'catalog';
    case 19:
      return 'violation';
    case 20:
      return 'order_block';
    case 21:
      return 'analytics';
    case 22:
      return 'order_object_log';
    case 23:
      return 'mbs_order_fetch';
    case 24:
      return 'mbs_ivucn_update';
    case 25:
      return 'manual_ath_movil_payment';
    case 26:
      return 'ebt_payment_received';
    case 27:
      return 'third_party_deliery';
    case 28:
      return 'note_on_order';
    case 29:
      return 'softek_ivu_control_fetch';
    case 30:
      return 'pos_fetch_date_update';
    case 31:
      return 'paypal_payment';
    case 32:
      return 'stripe_reversal';
    case 33:
      return 'paypal_refund';
    case 34:
      return 'product_update_object_log';
    case 35:
      return 'entry';
    case 36:
      return 'paypal_daily_transfers';
    case 37:
      return 'update';
    case 38:
      return 'reorder';
    case 39:
      return 'customer_service';
    case 40:
      return 'card_security_activation';
    case 41:
      return 'product_creation';
    case 42:
      return 'custom_credit';
    case 43:
      return 'product_credit';
    case 44:
      return 'bins';
    case 45:
      return 'ath_movil_return';
    case 46:
      return 'additional_order';
    case 47:
      return 'business_hours';
    case 48:
      return 'paypal_daily_transfer_failure';
    case 49:
      return 'mbs_ivu_log';
    default:
      return 'unknown';
  }
};

export const isEmpty = (s) => {
  if (s) {
    return s.length === 0;
  }
  return true;
};

export const eventTypes = [
  { type: 0, name: 'rental' },
  { type: 1, name: 'class' },
  { type: 2, name: 'other' },
  { type: 3, name: 'yoga' },
  { type: 4, name: 'service' }
];

export const eventSubscriberVisibility = [
  { type: 1, name: 'free' },
  { type: 2, name: 'tier1' },
  { type: 4, name: 'tier2' },
  { type: 8, name: 'tier3' },
  { type: 16, name: 'tier4' },
  { type: 32, name: 'any_subscription' }
];

export const bookingStatus = [
  { type: 0, name: 'pending' },
  { type: 1, name: 'confirmed' },
  { type: 2, name: 'cancelled' }
];

export const posDiscountTypes = [
  { type: 0, name: 'fixed' },
  { type: 1, name: 'percentage' },
  { type: 2, name: 'no_state_tax' },
  { type: 3, name: 'no_municipal_tax' },
  { type: 4, name: 'no_tax' },
];

export const mapPosDiscountType = (type, value) => {
  const returnVal = { name: '', value: '-' };
  const filtered = _.filter(posDiscountTypes, (e) => e.type.toString() === type.toString());
  if (filtered.length > 0) {
    returnVal.name = filtered[0].name;
    if (value && type === 0) {
      returnVal.value = toCurrency(parseFloat(value.toString()));
    } else if (value && type === 1) {
      returnVal.value = `${(parseFloat(value.toString()) * 100).toFixed(2)}%`;
    }
  }
  return returnVal;
};

export const getEventBookingStatus = (type) => {
  const filtered = _.filter(bookingStatus, (e) => e.type === type);
  if (filtered.length > 0) {
    return filtered[0].name;
  }
  return '';
};

export const getEventTypeName = (type) => {
  const filtered = _.filter(eventTypes, (e) => e.type === type);
  if (filtered.length > 0) {
    return filtered[0].name;
  }
  return '';
};

export const getEventSubscriberVisibilityName = (type) => {
  const filtered = _.filter(eventSubscriberVisibility, (e) => e.type === type);
  if (filtered.length > 0) {
    return filtered[0].name;
  }
  return '';
};

export const convertMinutesToDuration = (inputMins, shouldFloorMinutes) => {
  if (inputMins) {
    let days = 0;
    let hours = 0;
    let mins = 0;
    if (inputMins < 60) {
      mins = inputMins;
      if (shouldFloorMinutes) {
        return `${Math.floor(mins)}m`;
      }
      return `${Math.round(mins)}m`;
    }
    if (inputMins < 1440) {
      hours = Math.floor(inputMins / 60);
      mins = inputMins - (hours * 60);
      if (mins === 0) {
        return `${hours}h`;
      }

      if (shouldFloorMinutes) {
        return `${hours}h ${Math.floor(mins)}m`;
      }
      return `${hours}h ${Math.round(mins)}m`;
    }

    days = Math.floor(inputMins / 1440);
    hours = Math.floor((inputMins - (days * 1440)) / 60);
    mins = inputMins - ((days * 1440) + (hours * 60));
    let result = '';
    if (days > 0) {
      result += `${days}d`;
    }
    if (hours > 0) {
      result += ` ${hours}h`;
    }
    if (mins > 0) {
      if (shouldFloorMinutes) {
        result += ` ${Math.floor(mins)}m`;
      } else {
        result += ` ${Math.round(mins)}m`;
      }
    }
    return result.trim();
  }
  return '0m';
};

export const durationBetweenTwoDates = (d1, d2) => {
  if (d1 && d2) {
    const startDate = new Date(d1);
    const endDate = new Date(d2);
    const timeDiff = endDate - startDate;

    const yearDiff = endDate.getFullYear() - startDate.getFullYear();
    const monthDiff = endDate.getMonth() - startDate.getMonth();
    const dayDiff = endDate.getDate() - startDate.getDate();
    const hourDiff = endDate.getHours() - startDate.getHours();
    const minuteDiff = endDate.getMinutes() - startDate.getMinutes();

    let totalDays = yearDiff * 365.25 + monthDiff * 30.44 + dayDiff;

    const years = Math.floor(totalDays / 365.25);
    totalDays -= years * 365.25;

    const months = Math.floor(totalDays / 30.44);
    totalDays -= months * 30.44;

    const days = Math.floor(totalDays);
    // console.log({ years, months, days, hours: hourDiff, minutes: minuteDiff });

    let result = '';
    if (years > 0) {
      result += `${years}y `;
    }
    if (months > 0) {
      result += `${months}mo `;
    }
    if (days > 0) {
      result += `${days}d `;
    }
    if (hourDiff > 0) {
      result += `${hourDiff}h `;
    }
    if (minuteDiff > 0) {
      result += `${minuteDiff}m `;
    }
    return result.trim();
  }
  return '0m';
};

export const numberWithCommas = (x) => {
  const val = x.toFixed(2);
  if (!val) {
    return 0;
  }
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getRecurranceEndDate = (startDate, recurrence) => {
  let tempDate = moment(startDate.toString());
  if (recurrence === 1) {
    tempDate = tempDate.add(1, 'day');
  } else if (recurrence === 2) {
    tempDate = tempDate.add(7, 'day');
  } else if (recurrence === 3) {
    tempDate = tempDate.add(14, 'day');
  } else if (recurrence === 4) {
    tempDate = tempDate.add(1, 'month');
  } else if (recurrence === 5) {
    tempDate = tempDate.add(2, 'month');
  } else if (recurrence === 6) {
    tempDate = tempDate.add(3, 'month');
  } else if (recurrence === 7) {
    tempDate = tempDate.add(6, 'month');
  } else if (recurrence === 8) {
    tempDate = tempDate.add(1, 'year');
  }
  return tempDate;
};

export const getDateExtensionEndDate = (date, dateExtensionType, dateExtensionValue) => {
  let tempDate = moment(date.toString());
  if (dateExtensionType === 1) {
    tempDate = tempDate.add(dateExtensionValue, 'day');
  } else if (dateExtensionType === 2) {
    tempDate = tempDate.add(dateExtensionValue, 'week');
  } else if (dateExtensionType === 3) {
    tempDate = tempDate.add(dateExtensionValue, 'month');
  } else if (dateExtensionType === 4) {
    tempDate = tempDate.add(dateExtensionValue, 'year');
  }
  return tempDate;
};

export const formatUserSinceDate = (date, timezone) => {
  // Parse the date string back into a moment object using the known format
  const createdAt = moment.tz(date, 'MMMM Do YYYY, h:mm:ss a', timezone);
  if (!createdAt.isValid()) {
    return 'Invalid date';
  }

  const now = moment.tz(timezone);
  const yearsDifference = now.diff(createdAt, 'years');
  createdAt.add(yearsDifference, 'years');
  const monthsDifference = now.diff(createdAt, 'months');
  createdAt.add(monthsDifference, 'months');
  const daysDifference = now.diff(createdAt, 'days');

  let yearString = '';
  if (yearsDifference >= 1) {
    yearString = `${yearsDifference} year${yearsDifference !== 1 ? 's' : ''}, `;
  }

  return `(${yearString}${monthsDifference} month${monthsDifference !== 1 ? 's' : ''}, ${daysDifference} day${daysDifference !== 1 ? 's' : ''})`;
};
